const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6970097', space_js: '//acjfpbd.cjdigit.com/production/qlk_t/static/m/rrt/m.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6970098', space_js: '//acjfpbd.cjdigit.com/common/r/production/m_lu/source/n/openjs/ssuo.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6970099', space_js: '//acjfpbd.cjdigit.com/source/snmvo-tt-v/production/v.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6970100', space_js: '//acjfpbd.cjdigit.com/site/bwv/common/ex_cf/production/cc.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Home_native: NATIVE_1,
    Home_native_center1: NATIVE_2,
    Detail_inter: INTER,
    Detail_banner: BANNER,
    Detail_native: NATIVE_1,
};
